import React from 'react';
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap';
import { BsChevronRight, BsReceipt, BsBoundingBoxCircles, BsImages } from 'react-icons/bs';
import '../styles/whyus.css'

const WhyUsSection = () => {
  return (
    <section id="why-us" className="why-us">
      <Container>
        <Row>
          <Col lg={4} className="d-flex align-items-stretch">
            <div className="content">
              <h3>HOW WE WORK?</h3>
              
              <p>
      <ListGroup.Item>Through Cooperation Agreement with Government Offices</ListGroup.Item>
      <ListGroup.Item>Partnership with Private Business Organizations</ListGroup.Item>
      <ListGroup.Item>Cooperative Agreement with Civil Society Organizations</ListGroup.Item>
      <ListGroup.Item>Partnership and Cooperative Agreements with International Humanitarian &amp; Development Organizations</ListGroup.Item>
    </p>
              
              <div className="text-center">
                <Button href="#" className="more-btn">Learn More <BsChevronRight /></Button>
              </div>
            </div>
          </Col>
          <Col lg={8} className="d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <Row>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <BsReceipt />
                    <h4>SERVICE  DELIVERY MODEL</h4>
                    <p>
                    We are committed to supporting your child’s unique journey. 
                    Our focus is on providing exceptional and specialized care, 
                    and steadfast support for families like yours. 
                    {/* We believe in the boundless potential of every child and adults,
                     and our experienced team of professionals is here to help unlock it. 
                     Bright Path Autism and mental Health Support  is more than just a support center; 
                     we’re a source of hope and progress. */}
                    </p>
                  </div>
                </Col>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <BsBoundingBoxCircles />
                    <h4>DIFFERENCIATORS</h4>
                    <ListGroup>
                      <ListGroup.Item><h6>Accept</h6></ListGroup.Item>
                      <ListGroup.Item><h6>Enrich</h6></ListGroup.Item>
                      <ListGroup.Item><h6>Inspire</h6></ListGroup.Item>
                      <ListGroup.Item><h6>Empower</h6></ListGroup.Item>
</ListGroup>
              	
                  </div>
                </Col>
                <Col xl={4} className="d-flex align-items-stretch">
                  <div className="icon-box mt-4 mt-xl-0">
                    <BsImages />
                    <h4>BPAMHS</h4>
<ListGroup>
  <ListGroup.Item><h6 class="highlight">B: Bright</h6></ListGroup.Item>
  <ListGroup.Item><h6 class="highlight">P: Path</h6></ListGroup.Item>
  <ListGroup.Item><h6 class="highlight">A: Autism</h6></ListGroup.Item>
  <ListGroup.Item><h6 class="highlight">M: Mental</h6></ListGroup.Item>
  <ListGroup.Item><h6 class="highlight">H: Health</h6></ListGroup.Item>
  <ListGroup.Item><h6 class="highlight">S: Support</h6></ListGroup.Item>
</ListGroup>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhyUsSection;