import { Container, Row, Col } from 'react-bootstrap';
import { BsTwitter, BsFacebook, BsInstagram, BsSkype, BsLinkedin } from 'react-icons/bs';
import '../styles/footer.css'
const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-top">
        <Container>
          <Row>
            <Col lg={3} md={6} className="footer-contact">
              <h3>BPAMHS</h3>
              <p>
                A108 Adam Street <br />
                New York, NY 535022<br />
                United States <br /><br />
                <strong>Phone:</strong> + 1-602-503- 2183<br />
                <strong>Email:</strong> brightpathfacility@gmail.com<br />
              </p>
            </Col>

            <Col lg={2} md={6} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About us</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Policies</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#">Guiding principles</a></li>
              </ul>
            </Col>

            <Col lg={3} md={6} className="footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><a href="#services">Behavior Assessments</a></li>
                <li><a href="#services">Early Intervention Services</a></li>
                <li><a href="#services">Parent Training</a></li>
                <li><a href="#services">Speech Therapy</a></li>
                <li><a href="#services">School Consulation</a></li>
                <li><a href="#services">Social Skill Groups</a></li>
              </ul>
            </Col>

            <Col lg={4} md={6} className="footer-newsletter">
              <h4>Join Us</h4>
              <p>Subscribe to our newsletter and stay up-to-date with the latest news, offers, and updates!"</p>
              <form action="" method="post">
                <input type="email" name="email" /><input type="submit" value="Subscribe" />
              </form>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            &copy; {new Date().getFullYear()} Copyright <strong><span>Autism</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="#">Aregawi H.Hagos</a>
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" className="twitter"><BsTwitter /></a>
          <a href="#" className="facebook"><BsFacebook /></a>
          <a href="#" className="instagram"><BsInstagram /></a>
          <a href="#" className="google-plus"><BsSkype /></a>
          <a href="#" className="linkedin"><BsLinkedin /></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;