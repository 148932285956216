import React from 'react';
import { ListGroup } from 'react-bootstrap';
import '../styles/stackholderlistsection.css'
const InHousePoliciesListSection = () => {
  const policies = [
    'BPAMHS Computer Use Policy 2013',
    'BPAMHS Financial Manual',
    'BPAMHS Human Resource Manual',
    'BPAMHS In-house Child Protection Guidline',
    'BPAMHS Anti-fraud Policy 2023',
    'BPAMHS Compliant and Response Mechanism Guidline',
    'BPAMHS Communication Policy and Procedure',
    'BPAMHS Employement and Affirmative Action Policy 2023',
    'BPAMHS Health Care Policy 2023',
    'BPAMHS Organizational Monitoring and Evaluation Policy 2023',
    'BPAMHS Procurement Manual',
    'BPAMHS Safeguarding Policy 2023',
    'BPAMHS Voluntarism Policy 2023',
    'Code of Ethics- BPAMHS',
    'Guidlinefor Pse - BPAMHS - Ethiopia - 2023'
  ];
  const listStyle = {
    listStyle: 'none', // Remove default list style
    paddingLeft: '0', // Remove left padding to adjust for the missing bullet
  };

  return (
    <section>
    <div className="section-title" style={{borderTopLeftRadius:'100px'}}>
     <h2>BPAMHS IN-HOUSE POLICIES AND GUIDELINES </h2>
     
   </div>
    <ListGroup style={listStyle}>
      {policies.map((policy, index) => (
        <ListGroup.Item   key={index}><a className="text-decoration-none" href='#'><h5>{policy}</h5></a></ListGroup.Item>
      ))}
    </ListGroup>
    </section>
  );
};

export default InHousePoliciesListSection;