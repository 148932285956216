import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/partner.css'; // Create a separate CSS file for custom styles

const PartnerLogos = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className='partner-logos'>
      <h2>Our Partners/Our Clients</h2>
      <Slider {...settings}>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/luxury-letter-e-logo-design_1017-8903.jpg" alt="Logo 1" />
        </div>
        <div className="partner-logo">
          <img src="http://www.webcoderskull.com/img/logo.png" alt="Logo 2" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/3d-box-logo_1103-876.jpg" alt="Logo 3" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/blue-tech-logo_1103-822.jpg" alt="Logo 4" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/colors-curl-logo-template_23-2147536125.jpg" alt="Logo 5" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/abstract-cross-logo_23-2147536124.jpg" alt="Logo 6" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/football-logo-background_1195-244.jpg" alt="Logo 7" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/background-of-spots-halftone_1035-3847.jpg" alt="Logo 8" />
        </div>
        <div className="partner-logo">
          <img src="https://image.freepik.com/free-vector/retro-label-on-rustic-background_82147503374.jpg" alt="Logo 9" />
        </div>
      </Slider>

    </div>
  );
};

export default PartnerLogos;