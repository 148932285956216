import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone,  } from '@fortawesome/free-solid-svg-icons';
import {  Twitter, Facebook, Instagram, Linkedin } from 'react-bootstrap-icons';
import  '../styles/topbar.css'
 const TopBarPage = () => {
  const handlePhoneClick = () => {
    window.location.href = 'tel:+251911874530';
  };
  return (
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-between">
        <div className="contact-info d-flex align-items-center">
       <span> <a href="mailto:contact@example.com">
         <FontAwesomeIcon icon={faEnvelope} />brightpathfacility@gmail.com</a></span>
        <span className='ms-5'><a onClick={handlePhoneClick}>
<FontAwesomeIcon icon={faPhone} /> +251 911874530
        </a></span>
         
        </div>
        <div className="d-none d-lg-flex social-links align-items-center">
          <a href="#" className="twitter"><h4><Twitter /></h4></a>
          <a href="#" className="facebook"><h4><Facebook /></h4></a>
          <a href="#" className="instagram"><h4><Instagram /></h4></a>
          <a href="#" className="linkedin"><h4><Linkedin /></h4></a>
        </div>
      </div>
    </div>
  );
};

export default TopBarPage;