import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BsFingerprint, BsGift, BsCodeSlash  } from 'react-icons/bs';
import '../styles/about.css'

const AboutSection = () => {
  return (
    <section id="about" className="about">
      <Container fluid>
        <Row>
        

          <Col xl={7} lg={6} className="icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
            <h3>Why you should choose us</h3>
            <p>
            {/* Bright Path Autism and mental Health Support strongly believes that Autism is not a disability, it's a different ability. Bright Path Autism and mental Health Support helps children with autism and adults with mental health challenges. Bright Path Autism and mental Health Support is committed to better understanding the aspirations of people with autism spectrum disorder and helping them to fulfill their potential to lead positive, productive and rewarding lives, through the development and delivery of interventions, educational programs, training programs, and recreational programs, and global outreach; and raising awareness and promoting inclusion of people on the autism spectrum.  We do focus to learn the importance of skills and how to improve them. They provide hands-on learning experiences that teach children and adults how to care for themselves. These lessons often cater to the child's and adults home environment to make them more relatable and easy to follow. Our support uses behavioral interventions to teach children and adults how to interact with their peers. Learning how to interact with others improves happiness, increases participation in community activities, and helps children find a sense of belonging. */}

            Bright Path, headquartered in the USA, is a global initiative passionate about supporting children with autism and adults facing mental health challenges. We offer a unique hybrid approach through philanthropic and corporate services, currently expanding across Eastern Africa and Europe. Our mission is to empower individuals on the autism spectrum to reach their full potential and lead fulfilling lives. We achieve this through personalized interventions, educational programs, training initiatives, and recreational activities. We also raise awareness and advocate for autism inclusion.  Furthermore, Bright Path focuses on skill development and self-care, providing hands-on learning experiences that equip individuals with essential life skills for independent living and successful social interaction. Ultimately, Bright Path champions a holistic approach to well-being, utilizing evidence-based behavioral interventions to foster positive, productive, and rewarding lives for all.            </p>

          

          </Col>

          <Col xl={4} lg={5} className="video-box d-flex justify-content-center align-items-stretch position-relative">
            <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox play-btn mb-4"></a>
          </Col>
        </Row>
        <Row className="justify-content-center">
  <Col xl={4} lg={5}>
    <div className="icon-box">
      <div className="icon mt-5"><BsFingerprint /></div>
      <h4 className="title"><a href="" className='ms-5'>Vision</a></h4>
      <p className="description">We envision a world where all youth and adults on the autism spectrum and with mental health needs are embraced by their communities and empowered to achieve their unique goals</p>
    </div>
  </Col>
  <Col xl={4} lg={5}>
    <div className="icon-box">
      <div className="icon mt-5"><BsGift /></div>
      <h4 className="title"><a href="" className='ms-5'>Mission</a></h4>
      <p className="description">Acceptance & Respect: We champion a society that embraces all youth and adults on the autism and mental health spectrums.<br />
        Empowering Potential: We empower each individual to achieve their full potential and thrive.</p>
    </div>
  </Col>
  <Col xl={4} lg={5}>
    <div className="icon-box">
      <div className="icon mt-5"><BsCodeSlash /></div>
      <h4 className="title"><a href="" className='ms-5'>VALUES</a></h4>
      <p className="description">Empowering, Inclusive Team: We value kindness, honesty, and respect for everyone. All voices are heard, and we support diverse needs through individualized plans. We're creative and inclusive, ensuring everyone has development opportunities. We take responsibility for continuous learning.</p>
    </div>
  </Col>
</Row>
      </Container>
    </section>
  );
};

export default AboutSection;