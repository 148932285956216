import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { BiMap, BiEnvelope, BiPhone } from 'react-icons/bi';
import '../styles/contact.css'
import { ContactService } from '../services/contact.service';
import { Formik } from 'formik';
import * as Yup from 'yup';

const ContactSection = () => {

    const initialValues = {
      full_name: '',
      email: '',
      subject: '',
      message: '',
    };
  
    const validationSchema = Yup.object().shape({
      full_name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required'),
    });
const contactService = new ContactService();
const handleSubmit = async (values, { setSubmitting, resetForm }) => {
  try {
      console.log(values);
            await contactService.addContact(values);
            resetForm();
            setSubmitting(false);
        console.log('About data added successfully');
    

      // resetForm();
    } catch (error) {
      console.error('Failed to save about data:', error.message);
    }
  };
  return (
    <section id="contact" className="contact">
      <Container>
        <div className="section-title">
          <h2>Need any help?</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
      </Container>

      {/* <div>
        <iframe title="Google Maps" style={{ border: 0, width: '100%', height: '350px' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" allowFullScreen></iframe>
      </div> */}

      <Container>
        <Row className="mt-5">
          <Col lg={4}>
            <div className="info">
              <div className="address">
                <BiMap />
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>

              <div className="email">
                <BiEnvelope />
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>

              <div className="phone">
                <BiPhone />
                <h4>Call:</h4>
                <p>+1 5589 55488 55s</p>
              </div>
            </div>
          </Col>

          <Col lg={8} className="mt-5 mt-lg-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="php-email-form" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6} className="form-group">
                    <Form.Control
                      type="text"
                      name="full_name"
                      placeholder="Your Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.full_name}
                      isInvalid={touched.full_name && errors.full_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.full_name}
                    </Form.Control.Feedback>
                  </Col>
                  <Col md={6} className="form-group mt-3 mt-md-0">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={touched.email && errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Form.Group className="mt-3">
                  <Form.Control
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                    isInvalid={touched.subject && errors.subject}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.subject}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Control
                    as="textarea"
                    name="message"
                    rows={5}
                    placeholder="Message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    isInvalid={touched.message && errors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="my-3">
                  <div className="text-center">
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Send Message'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;