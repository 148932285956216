import React from 'react';
import { ListGroup } from 'react-bootstrap';
import '../styles/stackholderlistsection.css'


const StakeholdersListSection = () => {
  const stakeholders = [
    'Children affected by autism',
    'Individuals with mental health challenges',
    'Public Health Care Workers and supportive staff',
    'Personnel in pharmacies and dispensaries',
    'Traditional healer and religious leader',
    'Volunteers and/or community workers',
    'MoH and EPHI',
    'Ministry of Women, Children and Youth',
    'Ministry of Transport',
    'Ministry of Peace',
    'Other Public authorities',
    'Ethiopian Airlines and Travel agents',
    'Africa CDC and WHO',
    'UN agencies - UNCEF, UNFAPA',
    'Academic institutes',
    'Homeless people and those living in informal settlements or urban slums',
    'Disadvantaged or persecuted ethnic, racial, gender, and religious groups, and sexual minorities and people living with disabilities',
    'Hard to reach population groups'
  ];

  return (
    <section>
         <div className="section-title">
          <h2>Key Stakeholders for Autism and Mental Health Support </h2>
          
        </div>

    <ListGroup className="custom-list">
      {stakeholders.map((stakeholder, index) => (
        <ListGroup.Item key={index}><a href='#' className="text-decoration-none"><h5>{stakeholder}</h5></a></ListGroup.Item>
      ))}
    </ListGroup>
    </section>
  );
};

export default StakeholdersListSection;