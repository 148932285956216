import {React,useEffect} from 'react';
import { Container, Form, Button, OverlayTrigger,Tab, Nav,Col,Row } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import Tooltip from 'react-bootstrap/Tooltip';
import '../styles/donate.css'

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Card owner name is required'),
  cardNumber: Yup.string().required('Card number is required'),
  expirationMonth: Yup.number().required('Expiration month is required'),
  expirationYear: Yup.number().required('Expiration year is required'),
  cvv: Yup.string().required('CVV is required'),
});

const DonatePage = () => {
 
    
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form submitted');
      };
  return (
    <div className="container py-5">
    {/*<!---- For demo purpose --> */}
    <div className="row mb-4">
        <div className="col-lg-8 mx-auto text-center">
            <h1 className="display-6">Make a Difference: Donate to Support Autism</h1>
        </div>
    </div> {/*<!---- End --> */}
    <div className="row">
        <div className="col-lg-12 mx-auto">
            <div className="card ">
                <div className="card-header">
                        {/*<!---- Credit card form tabs --> */}
                        <Tab.Container defaultActiveKey="#credit-card">

                        <Nav variant="pills" className="nav-pills rounded nav-fill bg-white custom-tab-header mb-3" defaultActiveKey="#credit-card">
  <Nav.Item>
    <Nav.Link eventKey="#credit-card" className="nav-link">
      <i className="fas fa-credit-card mr-2"></i> Credit Card
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="#paypal" className="nav-link">
      <i className="fab fa-paypal mr-2"></i> Paypal
    </Nav.Link>
  </Nav.Item>
  <Nav.Item>
    <Nav.Link eventKey="#net-banking" className="nav-link">
      <i className="fas fa-mobile-alt mr-2"></i> Net Banking
    </Nav.Link>
  </Nav.Item>
</Nav>
                    {/*<!---- Credit card form content --> */}
 
                

<Tab.Content>
  {/* PayPal tab */}
  <Tab.Pane eventKey="#credit-card" className="tab-pane fade pt-3">
  <Form onSubmit={(event) => event.preventDefault()}>
                        <Form.Group>
                            <Form.Label>
                            <h6>Card Owner</h6>
                            </Form.Label>
                            <Form.Control type="text" name="username" placeholder="Card Owner Name" required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                            <h6>Card number</h6>
                            </Form.Label>
                            <Row>
                            <Col>
                                <Form.Control type="text" name="cardNumber" placeholder="Valid card number" required />
                            </Col>
                            <Col>
                                <div className="Form.Control-group-append">
                                <span className="Form.Control-group-text text-muted">
                                    <i className="fab fa-cc-visa mx-1"></i>
                                    <i className="fab fa-cc-mastercard mx-1"></i>
                                    <i className="fab fa-cc-amex mx-1"></i>
                                </span>
                                </div>
                            </Col>
                            </Row>
                        </Form.Group>
                        <Row>
                            <Col sm={8}>
                            <Form.Group>
                                <Form.Label>
                                <span className="hidden-xs">
                                    <h6>Expiration Date</h6>
                                </span>
                                </Form.Label>
                                <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="MM" name="" className="form-control" required />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="YY" name="" className="form-control" required />
                                </Col>
                                </Row>
                            </Form.Group>
                            </Col>
                            <Col sm={4}>
                            <Form.Group>
                                <Form.Label data-toggle="tooltip" title="Three digit CV code on the back of your card">
                                <h6>CVV <i className="fa fa-question-circle d-inline"></i></h6>
                                </Form.Label>
                                <Form.Control type="text" required className="form-control" />
                            </Form.Group>
                            </Col>
                        </Row>
                        <div className="card-footer">
                            <Button type="button" className="subscribe btn btn-primary btn-block shadow-sm">Confirm Payment</Button>
                        </div>
                        </Form>
    </Tab.Pane>
  <Tab.Pane eventKey="#paypal" className="tab-pane fade pt-3">
    <Form>
      <h6 className="pb-2">Select your PayPal account type</h6>
      <Form.Group className="form-group">
        <Form.Check
          type="radio"
          name="optradio"
          id="domestic"
          label="Domestic"
          checked
        />
        <Form.Check
          type="radio"
          name="optradio"
          id="international"
          label="International"
          className="ml-5"
        />
      </Form.Group>
      <p>
        <Button type="button" className="btn">
          <i className="fab fa-paypal mr-2"></i> Log into my PayPal
        </Button>
      </p>
      <p className="text-muted">
        Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order.
      </p>
    </Form>
  </Tab.Pane>

  {/* Net Banking tab */}
  <Tab.Pane eventKey="#net-banking" className="tab-pane fade pt-3">
    <Form>
      <Form.Group>
        <Form.Label>
          <h6>Select your Bank</h6>
        </Form.Label>
        <Form.Control as="select" id="ccmonth">
          <option value="" disabled selected>--Please select your Bank--</option>
          <option>Bank 1</option>
          <option>Bank 2</option>
          <option>Bank 3</option>
          <option>Bank 4</option>
          <option>Bank 5</option>
          <option>Bank 6</option>
          <option>Bank 7</option>
          <option>Bank 8</option>
          <option>Bank 9</option>
          <option>Bank 10</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <p>
          <Button type="button" className="btn">
            <i className="fas fa-mobile-alt mr-2"></i> Proceed Payment
          </Button>
        </p>
      </Form.Group>
      <p className="text-muted">
        Note: After clicking on the button, you will be directed to a secure gateway for payment. After completing the payment process, you will be redirected back to the website to view details of your order.
      </p>
    </Form>
  </Tab.Pane>
</Tab.Content>
</Tab.Container>
            </div>
        </div>
    </div>
    </div>
    </div>
  );
  };
export default DonatePage;