import React, { useState } from 'react';
import { BiX } from 'react-icons/bi';
import { Navbar, Nav, NavDropdown,Image,Button } from 'react-bootstrap';
import { BiChevronDown, BiChevronRight, BiMenu } from 'react-icons/bi';
import logoLeft from '../assets/logo.png';
import Convention from '../assets/autism/pdf/Convention.pdf';
import Ethiopia_National from '../assets/autism/pdf/Ethiopia-National.pdf';
import '../styles/header.css'
import PDFReader from '../pages/PdfReader';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <header id="header" className="fixed-top">
      <div className=" d-flex align-items-center mt-0 ml-5">
        {/* <h1 className="logo me-auto"><a href="index.html">Medilab</a></h1> */}
        {/* Uncomment below if you prefer to use an image logo */}
        <a href="/" className="logo me-auto">
        <Image src={logoLeft} className="img-fluid" />


          </a>

          <Navbar id="navbar" className="navbar order-last order-lg-0" expand="lg">
      <Navbar.Toggle onClick={handleToggleMenu}>
        {showMenu ? <BiX className="bi" /> : <BiMenu className="bi" />}
      </Navbar.Toggle>
      <Navbar.Collapse className={showMenu ? 'show' : ''}>
        <Nav className="ml-auto">
          <Nav.Link className="nav-link scrollto active" href="#hero">
            Home
          </Nav.Link>
          <Nav.Link className="nav-link scrollto" href="#about">
            About
          </Nav.Link>
          <Nav.Link className="nav-link scrollto" href="#services">
            Services
          </Nav.Link>
          <NavDropdown title="Policies" id="dropdown-menu">
            <NavDropdown.Item href={Convention}>UN Interanational</NavDropdown.Item>
            <NavDropdown.Item href={Ethiopia_National}>Ethiopian National</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className="nav-link scrollto" href="#contact">
            Contact
          </Nav.Link>
          <Nav.Link className="nav-link scrollto" href="#principles">
            Principles
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
        <a href="#donate" className="appointment-btn scrollto"><span className="d-none d-md-inline"></span> Donate</a>
      </div>
    </header>
  );
};

export default Header;