import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaUserMd, FaHospital, FaFlask, FaAward } from 'react-icons/fa';
import { useEffect } from 'react';
import CountUp from 'react-countup';
import '../styles/countsection.css'
const CountsSection = () => {
  return (
    <section className="counts">
    <Container>
      <Row>
        <Col lg={3} md={6}>
          <div className="count-box">
            <FaUserMd />
            <CountUp start={0} end={85} duration={1} delay={0}>
              {({ countUpRef }) => (
                <span ref={countUpRef} className="purecounter" />
              )}
            </CountUp>
            <p>Therapyst</p>
          </div>
        </Col>
  
        <Col lg={3} md={6} className="mt-5 mt-md-0">
          <div className="count-box">
            <FaHospital />
            <CountUp start={0} end={18} duration={1} delay={0}>
              {({ countUpRef }) => (
                <span ref={countUpRef} className="purecounter" />
              )}
            </CountUp>
            <p>Psychiatrists</p>
          </div>
        </Col>
  
        <Col lg={3} md={6} className="mt-5 mt-lg-0">
          <div className="count-box">
            <FaFlask />
            <CountUp start={0} end={12} duration={1} delay={0}>
              {({ countUpRef }) => (
                <span ref={countUpRef} className="purecounter" />
              )}
            </CountUp>
            <p>Psychologists</p>
          </div>
        </Col>
  
        <Col lg={3} md={6} className="mt-5 mt-lg-0">
          <div className="count-box">
            <FaAward />
            <CountUp start={0} end={150} duration={1} delay={0}>
              {({ countUpRef }) => (
                <span ref={countUpRef} className="purecounter" />
              )}
            </CountUp>
            <p>Counselors</p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  );
};

export default CountsSection;