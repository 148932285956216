import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/hero.css';
import im1 from'../assets/autism/4.jpg'
import im2 from'../assets/autism/7.jpg'
import im3 from'../assets/autism/8.jpg'
import im4 from'../assets/autism/4.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
const HeroPage = () => {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const colors = ["#FFA200", "#990000", "#008000"]; // Array of different colors

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  

  const currentColor = colors[currentColorIndex];
 
  const headingStyle = {
    color: currentColor,
    transition: 'color 0.5s ease', // Use 'ease' or another CSS timing function
  };
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="slider-container" data-aos="fade-in">
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
          <div className="slider-item">
            <img src={im1} alt="Slider 1" />
          </div>
          <div className="slider-item">
            <img src={im2} alt="Slider 2" />
          </div>
          <div className="slider-item">
            <img src={im3} alt="Slider 3" />
          </div>
        </Carousel>
      </div>
      <Container>
        <h1 style={headingStyle}>BRIGHT PATH AUTISM & MENTAL HEALTH SUPPORT</h1>
        <h2>“Autism is not a disability, it's a different ability.” – Stuart Duncan.”</h2>
        <Button href="#about" className="btn-get-started scrollto">Get Started</Button>
      </Container>
    </section>
  );
};

export default HeroPage;