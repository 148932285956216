import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaHeartbeat, FaPills, FaHospitalUser, FaDna, FaWheelchair, FaNotesMedical } from 'react-icons/fa';
import '../styles/service.css'
const ServicesSection = () => {
  return (
    <section id="services" className="services">
      <Container>
        <div className="section-title">
          <h2>Services we provide
</h2>
          {/* <p>Our behavior assessments are through and evidence-based
We use assessments to understand each child’s strengths and challenges,
 allowing us to create effective treatment plans.</p> */}
        </div>

        <Row>
          
        <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon"><FaNotesMedical /></div>
              <h4><a href="">Behavior Assessments</a></h4>
              <p>Our behavior assessments are through and evidence-based
We use assessments to understand each child’s strengths and challenges,
 allowing us to create effective treatment plans.</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box">
              <div className="icon"><FaHeartbeat /></div>
              <h4><a href="">Early Intervention Services</a></h4>
              <p>Early Intervention is crucial for children with autism. 
                our team   is experienced in providing early intervention
                 services that target critical developmental milestones, ensuring a strong foundation for future growth</p>
            </div>
          </Col>

          <Col lg={4} md={6} className="d-flex align-items-stretch mt-4 mt-lg-0">
            <div className="icon-box">
              <div className="icon"><FaPills /></div>
              <h4><a href="">Parent Training</a></h4>
              <p>
              We believe in empowering parents and caregivers with tools 
              and knowledge to support their child’s development at home.
               Our parent training programs provide valuable insights and strategies
                for creating a supportive home environment.
              </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><FaHospitalUser /></div>
              <h4><a href="">Speech Therapy</a></h4>
              <p>Our speech therapy services are designed to enhance communication and language development. Our speech therapist works with children to improve articulation, language comprehension and expressive communication. </p>
            </div>
          </Col>

          <Col lg={4} md={6} className="d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><FaDna /></div>
              <h4><a href="">School Consulation</a></h4>
              <p>We collaborate with schools to support children with autism in the
                           classroom. Our school consultation 
                          services help create inclusive and supportive learning environments.</p>
            </div>
          </Col>

          <Col lg={4} md={6} className="d-flex align-items-stretch mt-4">
            <div className="icon-box">
              <div className="icon"><FaWheelchair /></div>
              <h4><a href="">Social Skill Groups</a></h4>
              <p>Our social skills group offer children the opportunity to
                           practice social interaction in a structured and supportive setting.
                            We focus on building 
                          essential social skills that enhance relationships and self-confidence.</p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default ServicesSection;











