import React from 'react';
import { Container, ListGroup, Row, Col } from 'react-bootstrap';
import '../styles/principle.css'
import { FaHeartbeat, FaPills, FaHospitalUser, FaDna, FaWheelchair, FaNotesMedical } from 'react-icons/fa';

const GuidingPrinciples = () => {
  const principles = [
    {'Personalized Care': 'Every child is unique, and we tailor our therapies to meet their specific needs, ensuring the best outcomes.'},
    {'Professional Team': ' Our dedicated professionals are experts in their fields, providing top-class care and guidance.'},
    {'Evidence-Based Approaches': ' We rely on proven, evidence-based therapies that have consistently delivered positive results.'},
    {'Comprehensive Support': ' We offer a comprehensive range of services to support every aspect of a child’s development.'},
    {'Family-Centered': ' We empower families to actively participate in their child’s journey, fostering a collaborative and supportive environment.'},
    {'Inclusiveness': ' Our welcoming atmosphere ensures that children of all backgrounds feel comfortable and engaged during therapy sessions.'},
    {'Packaging affirmative outcomes': ' We celebrate every achievement, no matter how small, because each step forward is a significant milestone.'},
    {'Community Trust': ' We shall work to earn the trust of the community by delivering exceptional care and unwavering support to families.'}
  ];

  return (
        <section id="principles " className="principles">
      <Container>
        <div className="section-title">
          <h2>Our Guiding Principles</h2>
          
        </div>

        <Row>
       { principles.map((principle, index) => (
             
        <Col lg={3} md={4} className="d-flex">
        <div className="icon-box">
          {/* <div className="icon"><FaNotesMedical /></div> */}
          <h5><a href="">{Object.keys(principle)[0]}</a></h5>
          <p>{Object.values(principle)[0]}</p>
        </div>
      </Col>
        ))}
      </Row>
    </Container>
    </section>
  );
};

export default GuidingPrinciples;