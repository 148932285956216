import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { faCog, faComment, faDesktop, faImage, faTh, faUser } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Carousel } from 'react-bootstrap';
import '../styles/service.css'
import '../styles/newslist.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NewsService } from '../../services/news.service';
import { ServicesService } from '../../services/services.service';
import PartnerPage from './PartnerPage';
import { BiEnvelope, BiPhone, BiTwitter, BiFacebook, BiInstagram, BiLinkedin } from 'react-icons/bi';
import ServicePage from './ServicePage';
import HeroPage from './HeroPage';
import MainPage from './MainPage';

const HomePage = () => {
  // Example data for latest news and another section
  const [news, setNews]=useState([]);
  const [services, setServices]=useState([]);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const newsService = new NewsService();
  const serviceApiService = new ServicesService();
  const apiUrl = process.env.REACT_APP_API_URL;


  useEffect(() => {
    fetchNews();
    fetchServices();
  }, []);
  
  const fetchNews = async () => {
    try {
      const response = await newsService.getNews();
      setNews(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  
  const fetchServices = async () => {
    try {
      const response = await serviceApiService.getServices();
      console.log(response.data);
      setServices(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  

  return (
    <div>
     
         <HeroPage />
         <MainPage />   
         {/* <PartnerCarousel /> */}

         {/* <PartnerPage /> */}
     

    </div>
  );
};

export default HomePage;