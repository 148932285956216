import React, { useEffect } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import WhyUsSection from './WhyUsPage';
import AboutSection from './About';
import CountsSection from './CountsSection';
import ServicesSection from './ServicePage';
import DepartmentsSection from './Department';
import ContactSection from './Contact';
import GuidingPrinciples from './GuidingPrinciples';

import FAQSection from './FAQSection';
import GallerySection from './Gallery';
import PartnerLogos from './PartnerPage';
import StakeholdersListSection from './StackholderListSection';
import InHousePoliciesListSection from './InHousePoliciesList';
import DonatePage from './DonationPage';
import ScrollReveal from './ScrollReveal';
import '../styles/main.css'

const MainPage = () => {


  useEffect(() => {
    const sections = document.querySelectorAll('.section');

    const checkVisibility = () => {
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const isVisible = sectionTop < window.innerHeight - 100; // Adjust as needed

        if (isVisible) {
          section.classList.add('visible');
        }
      });
    };

    window.addEventListener('scroll', checkVisibility);
    checkVisibility(); // Initial check on load

    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);
  return (
    <main id="main">

<WhyUsSection />
<AboutSection />
<CountsSection />
<ServicesSection />
{/* <DepartmentsSection /> */}
<DonatePage />

<GuidingPrinciples />
<Row>
      <Col xs={12} md={6}>
        <StakeholdersListSection />
      </Col>
      <Col xs={12} md={6}>
        <InHousePoliciesListSection />
      </Col>
    </Row>
<ContactSection />

<FAQSection />
<PartnerLogos />
{/* <GallerySection /> */}
</main>
  );
};

export default MainPage;